<template>
  <div class="privacy-container">
    <div class="content" v-html="content" />


    <div >{{value}}</div>
  </div>
</template>

<script>
import request from "../../request";

export default {
  name: 'Demo',
  data() {
    return {
      content: ``,
      value: ``
    }
  },
  created() {

  },
  mounted() {
    this.getData()

    this.getValue() 
  },
  methods: {
    getValue() {
      request({
        url: `/api/guest/school/city?father=450000`,
        method: 'get',
      }).then(({data}) => {
        // console.log('res', res)
        this.value = data
      })
    },

    getData() {
      const key = 'app_privacy'

       request({
        url: `/api/misc/mini/config/key/${key}`,
        // url: 'https://api.xygeng.cn/one',
        method: 'get',
      }).then(({data}) => {
        // console.log('res', res)
        this.content = this.HTMLDecode(data.value)
      })
    },

    getContent() {
      const key = `app_privacy`

       var xmlhttp = new XMLHttpRequest();
       xmlhttp.open("GET", `https://staging-api.msy5.com/api/misc/mini/config/key/${key}`, true);
       xmlhttp.send();
        xmlhttp.onreadystatechange =  () => { 
          console.log('readyState', xmlhttp.readyState)
          console.log('xmlhttp.status', xmlhttp.status)
          if (xmlhttp.readyState === 4) { 
            if (xmlhttp.status>=200 && xmlhttp.status<300 ||xmlhttp.status===304 ){
              console.log('xmlhttp.responseText', xmlhttp.responseText)
              this.content = xmlhttp.responseText
                // console.log(JSON.parse(xmlhttp.responseText));
                // const response = JSON.parse(xmlhttp.responseText)
                // this.contentHtml = this.HTMLDecode(response.data.value)

            }else{
                console.log("没有接收到服务器的数据");
            }
          }
        }
    },

    // HTML进行反转义（html解码）
    HTMLDecode(text) {
      var reg = /<[^>]+>/g
      if (reg.test(text)) {
        return text
      }
      var temp = document.createElement('div')
      temp.innerHTML = text
      var output = temp.innerText || temp.textContent
      temp = null
      return output
    }
  }
}
</script>

<style lang="scss" scoped>
.privacy-container {
  padding: 30px 15px;

  .content-html {
    white-space: pre-line;
  }
}
</style>
